






























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DepartmentTypeSelect from '../DepartmentTypeSelect.vue';
import { CHANGE_CONTACT, DELETE_CONTACT } from '../../store';
import { Contact, WriteContact } from '../../types';
import { ApiResponse } from '@/components/types';
import { MapContactToWriteContact } from '../../model';

const Contact = namespace('contact');

@Component({
  components: {
    DepartmentTypeSelect
  }
})
export default class ChangeDepartmentDialog extends Vue {
  @Prop({ type: Object }) value?: Contact;
  @Prop({ type: Boolean, default: false }) dialog!: boolean;

  @Contact.Action(CHANGE_CONTACT) changeContact!: (contact: WriteContact) => Promise<ApiResponse>;
  @Contact.Action(DELETE_CONTACT) deleteContact!: (contact: WriteContact) => Promise<ApiResponse>;

  error: any = null;
  contact: WriteContact | null = null;

  get iDialog() {
    return this.dialog;
  }

  set iDialog(value: boolean) {
    this.$emit('update:dialog', value);
  }

  @Watch('value', { immediate: true })
  watchContact(value: Contact) {
    if (!value) {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;

      return;
    }

    this.contact = MapContactToWriteContact(value);
  }

  async save() {
    if (!this.contact) return {};

    this.error = null;

    return this.changeContact(this.contact);
  }

  async remove() {
    if (!this.contact) return {};

    this.error = null;

    return this.deleteContact(this.contact);
  }
}
